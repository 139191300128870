import { usePostLogoutMutation } from "@/api/queries/authQueries";
import SettingOptionHeader from "@/components/features/settings/SettingOptionHeader.js";
import ThemeModeButton from "@/components/features/settings/ThemeModeButton";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import ImageCropper from "@/components/ui/image-cropper/ImageCropper";
import { ThemeMode, useThemeContext } from "@/context/Theme";
import useGetAvatar from "@/hooks/useGetAvatar.js";
import { useLvl } from "@/hooks/useLvl";
import useMediaQueryHook from "@/hooks/useMediaQueryHook.js";
import { useCredentials, useRemoveCredentials } from "@/store/authStore";
import { Lock, LogOut, UploadCloud, User } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SettingsPage() {
  const navigate = useNavigate();
  const removeCredentials = useRemoveCredentials();
  const { name, surname, lvl, avatarURL } = useCredentials();
  const userRole = useLvl(lvl);

  const { setTheme, theme } = useThemeContext();

  const [openCropper, setOpenCropper] = useState<boolean>(false);

  const { mutateAsync, isPending } = usePostLogoutMutation();

  const isMobile = useMediaQueryHook("sm");

  const handleLogOut = async () => {
    await mutateAsync();
    removeCredentials();
    navigate("/login");
  };

  const handleClickPasswordChange = () => {
    navigate("/auth/passwordChange/");
  };

  return (
    <>
      <ImageCropper open={openCropper} onOpenChange={setOpenCropper} />
      <div
        className={
          "flex min-h-full flex-[1_0_auto] flex-col rounded-xl border-1 border-border bg-bg-container p-0"
        }
      >
        <div className={"flex items-center gap-6 p-6 pb-10"}>
          <Avatar className={"h-14 w-14"}>
            <AvatarImage src={useGetAvatar(avatarURL)} alt="avatar" />
            <AvatarFallback>
              <User className={"h-5 w-5"} />
            </AvatarFallback>
          </Avatar>
          <div>
            <h5 className={"text-lg font-semibold text-fg-primary"}>
              {name + " " + surname}
            </h5>
            <p className={"text-muted-foreground text-sm"}>{userRole}</p>
          </div>
        </div>
        <div className={"flex gap-6 border-t-1 border-border p-6 sm:flex-col"}>
          <SettingOptionHeader
            className={"w-[15rem] sm:w-full"}
            title={"Zmień motyw"}
            description={"Wybierz swój motyw i kliknij."}
          />
          <div className={"flex w-full gap-4"}>
            <ThemeModeButton
              label={"Jasny"}
              active={theme === ThemeMode.Light}
              themeMode={ThemeMode.Light}
              setThemeMode={() => setTheme(ThemeMode.Light)}
            />
            <ThemeModeButton
              label={"Ciemny"}
              active={theme === ThemeMode.Dark}
              themeMode={ThemeMode.Dark}
              setThemeMode={() => setTheme(ThemeMode.Dark)}
            />
          </div>
        </div>
        <div className={"flex gap-6 border-t-1 border-border p-6 sm:flex-col"}>
          <SettingOptionHeader
            className={"w-[15rem] sm:w-full"}
            title={"Zmień Awatar"}
            description={
              "Naciśnij, i zmień swojego awatara na jakiego tylko chcesz."
            }
          />
          <div className={"flex w-full items-center gap-4"}>
            <Avatar className={"h-13 w-13"}>
              <AvatarImage src={useGetAvatar(avatarURL)} alt="avatar" />
              <AvatarFallback>
                <User className={"h-5 w-5"} />
              </AvatarFallback>
            </Avatar>
            <Button
              icon={<UploadCloud />}
              iconPosition={"left"}
              variant={"outline"}
              variantColor={"muted"}
              onClick={() => setOpenCropper(true)}
            >
              Dodaj zdjęcie
            </Button>
          </div>
        </div>
        <div className={"flex gap-6 border-t-1 border-border p-6 sm:flex-col"}>
          <SettingOptionHeader
            className={"w-[15rem] sm:w-full"}
            title={"Zmień hasło"}
            description={"Naciśnij, przycisk aby zmienić swoje hasło."}
          />
          <div>
            <Button
              onClick={handleClickPasswordChange}
              variant={"outline"}
              variantColor={"muted"}
              iconPosition={"left"}
              icon={<Lock />}
            >
              Zmień hasło
            </Button>
          </div>
        </div>
        {isMobile && (
          <>
            <div
              className={"flex gap-6 border-t-1 border-border p-6 sm:flex-col"}
            >
              <SettingOptionHeader
                className={"w-[15rem] sm:w-full"}
                title={"Wyloguj się"}
                description={"Naciśnij, przycisk aby wylogować sie z konta."}
              />
              <div>
                <Button
                  isLoading={{
                    state: isPending,
                    message: "Wylogowywanie",
                  }}
                  onClick={handleLogOut}
                  variant={"outline"}
                  variantColor={"destructive"}
                  iconPosition={"left"}
                  icon={<LogOut />}
                >
                  Wyloguj się
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
