import { useGetDirectoryQuery } from "@/api/queries/directoryQueries";
import FilePageForms from "@/components/features/files/layouts/files-page/FilePageForms";
import FilesPageContent from "@/components/features/files/layouts/files-page/FilesPageContent";
import FilesPageHeader from "@/components/features/files/layouts/files-page/FilesPageHeader";
import MediaViewer from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import {
  FileUpload,
  FileUploadDrawer,
} from "@/components/ui/file-upload/FileUpload";
import useWriteFiles from "@/hooks/useWriteFiles";
import { useCredentials } from "@/store/authStore";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryDirI, DirectoryFileI } from "@/types/files";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format, isValid, parseISO } from "date-fns";
import { FileWithPath } from "file-selector";
import { ArrowUpDown } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export default function FilesPage() {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "Nazwa", desc: false },
  ]);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const {
    fileToOpen,
    model,
    resetSelected,
    openViewer,
    setOpenViewer,
    setPermissions,
  } = useFilesStorage();
  const { directoryId: defaultDirectoryId } = useCredentials();
  const { id } = useParams();
  const directoryId = useMemo(
    () => id || defaultDirectoryId,
    [id, defaultDirectoryId],
  );

  const {
    data: directory,
    isSuccess,
    isPending,
    isLoading,
    isError,
  } = useGetDirectoryQuery(directoryId, model === "my_drive");

  useEffect(() => {
    if (directory) {
      setPermissions(directory.permissions);
    }
  }, [directory, setPermissions]);

  useEffect(() => {
    resetSelected();
  }, [directoryId]);

  const { write, statuses, clearStatuses } = useWriteFiles();

  const handleOnDrop = (
    filesAccepted: FileList | (FileWithPath | DataTransferItem)[],
  ) => {
    write(filesAccepted, directoryId);
  };

  const handleOnChange = (
    filesAccepted: FileList | (FileWithPath | DataTransferItem)[],
  ) => {
    write(filesAccepted, directoryId);
  };

  const columns: ColumnDef<DirectoryFileI | DirectoryDirI>[] = useMemo(
    () => [
      {
        meta: "Nazwa",
        id: "Nazwa",
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Nazwa
            </Button>
          );
        },
        enableSorting: true,
        enableFilters: true,
      },
      {
        meta: "Ostatnie zmiany",
        id: "Ostatnie zmiany",
        accessorKey: "updatedAt",
        sortType: "datetime",
        cell: ({ getValue }) => {
          const date = getValue();
          if (typeof date === "string" && isValid(parseISO(date))) {
            return format(new Date(date), "dd.MM.yyyy");
          }
          return date;
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Ostatnie zmiany
            </Button>
          );
        },
      },
      {
        meta: "Utworzono",
        id: "Utworzono",
        accessorKey: "createdAt",
        sortType: "datetime",
        cell: ({ getValue }) => {
          const date = getValue();
          if (typeof date === "string" && isValid(parseISO(date))) {
            return format(new Date(date), "dd.MM.yyyy");
          }
          return date;
        },
        header: ({ column }) => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              icon={<ArrowUpDown />}
              iconPosition={"right"}
              className={"w-fit"}
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Utworzono
            </Button>
          );
        },
      },
    ],
    [],
  );

  const { data, dataLen } = useMemo(() => {
    if (directory) {
      const dirData = [...directory.files, ...directory.childDirs];
      return { data: dirData, dataLen: dirData.length };
    }
    return { data: [], dataLen: 0 };
  }, [directory]);
  const dataModel = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    //Row selection
    enableRowSelection: true,
    // onRowSelectionChange: setRowSelection,
    //Columns visibility
    onColumnVisibilityChange: setColumnVisibility,
    //Global filter
    enableFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    //Column filters
    onColumnFiltersChange: setColumnFilters,
    //Sorting
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      globalFilter,
      columnFilters,
    },
  });

  const fileIds = useMemo(
    () => new Set(directory?.files?.map((file) => file.id)),
    [directory?.files],
  );
  const childDirIds = useMemo(
    () => new Set(directory?.childDirs?.map((dir) => dir.id)),
    [directory?.childDirs],
  );

  const files = dataModel.getRowModel().rows.filter((element) => {
    return fileIds.has(element.original.id);
  }) as Row<DirectoryFileI>[];

  const directories = dataModel.getRowModel().rows.filter((element) => {
    return childDirIds.has(element.original.id);
  }) as Row<DirectoryDirI>[];

  return (
    <FileUpload onDrop={handleOnDrop} onChange={handleOnChange}>
      <FileUploadDrawer
        uploadData={statuses}
        onCloseCallback={() => clearStatuses()}
      />

      <FilePageForms directoryId={directoryId} />

      <MediaViewer
        file={fileToOpen}
        open={openViewer}
        onOpenChange={setOpenViewer}
      />

      <div className={"flex grow flex-col gap-4"}>
        <FilesPageHeader
          showTabsList={!id}
          fsEntry={directory}
          dataModel={dataModel}
          setGlobalFilter={setGlobalFilter}
          isLoading={isLoading}
          isError={isError}
        />
        <FilesPageContent
          files={files}
          directories={directories}
          dataLen={dataLen}
          isSuccess={isSuccess}
          isLoading={isPending}
          isError={isError}
        />
      </div>
    </FileUpload>
  );
}
