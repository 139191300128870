import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel/Carousel";
import { cn } from "@/lib/utils";
import { getWeek } from "@/utils/dateFormat";
import {
  addWeeks,
  endOfWeek,
  format,
  isSameDay,
  isToday,
  startOfWeek,
  subWeeks,
} from "date-fns";
import pl from "date-fns/locale/pl";
import { EmblaCarouselType } from "embla-carousel";
import { ChevronLeft, ChevronRight } from "lucide-react";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

type WeekRowProps = {
  selected: Date;
  onSelect: (day: Date) => void;
  visibleWeeksOffset?: number;
  currentVisibleWeekDay?: Date;
  setCurrentVisibleWeekDay?: Dispatch<SetStateAction<Date>>;
  renderContextMenu?: (
    content: JSX.Element,
    day: Date,
    key: string,
  ) => JSX.Element;
  className?: string;
};

export default function WeekCards(props: WeekRowProps) {
  const {
    selected = new Date(),
    onSelect,
    renderContextMenu,
    visibleWeeksOffset,
    setCurrentVisibleWeekDay,
    className,
  } = props;
  const selectedScrollSnapRef = useRef<number | undefined>(visibleWeeksOffset);
  const [weeks, setWeeks] = useState<Date[][]>([]);
  const [api, setApi] = useState<CarouselApi>();

  const handleOnClick = useCallback(
    (day: Date) => {
      onSelect?.(day);
    },
    [onSelect],
  );

  useEffect(() => {
    if (weeks.length !== 0) return;

    if (!visibleWeeksOffset) setWeeks([getWeek(new Date())]);
    else {
      const weeks: Date[][] = [];
      const referenceStartOfWeek = startOfWeek(new Date(), {
        weekStartsOn: 1,
      });
      for (let i = -visibleWeeksOffset; i <= visibleWeeksOffset; i++) {
        const weekStart = addWeeks(referenceStartOfWeek, i);
        weeks.push(getWeek(weekStart));
      }
      setWeeks(weeks);
    }
  }, [visibleWeeksOffset, weeks.length]);

  const setCurrentVisibleWeekDayCn = useCallback(
    (emblaEventApi: EmblaCarouselType) => {
      const prevSelectedScrollSnap = selectedScrollSnapRef.current;
      const isPrevSelectedScrollSnap = prevSelectedScrollSnap !== undefined;
      const newSelectedScrollSnap = emblaEventApi.selectedScrollSnap();

      if (
        isPrevSelectedScrollSnap &&
        prevSelectedScrollSnap < newSelectedScrollSnap
      ) {
        setCurrentVisibleWeekDay?.((prev) =>
          endOfWeek(addWeeks(prev, 1), {
            weekStartsOn: 1,
          }),
        );
      }
      if (
        isPrevSelectedScrollSnap &&
        prevSelectedScrollSnap > newSelectedScrollSnap
      ) {
        setCurrentVisibleWeekDay?.((prev) =>
          endOfWeek(subWeeks(prev, 1), {
            weekStartsOn: 1,
          }),
        );
      }
      if (isPrevSelectedScrollSnap) {
        selectedScrollSnapRef.current = newSelectedScrollSnap;
      }
    },
    [setCurrentVisibleWeekDay],
  );

  useEffect(() => {
    api?.on("select", setCurrentVisibleWeekDayCn);
    api?.scrollTo(visibleWeeksOffset ?? 0, true);

    return () => {
      api?.off("select", setCurrentVisibleWeekDayCn);
    };
  }, [api, visibleWeeksOffset]);

  return (
    <div
      className={
        "relative m-auto flex w-full items-center gap-1 overflow-hidden px-8 sm:px-0"
      }
    >
      {visibleWeeksOffset ? (
        <Carousel
          setApi={setApi}
          className={"w-full"}
          opts={{ align: "center" }}
        >
          <CarouselContent>
            {weeks.map((week, index) => (
              <CarouselItem key={index}>
                <WeekCard
                  week={week}
                  selected={selected}
                  className={className}
                  onClick={handleOnClick}
                  renderContextMenu={renderContextMenu}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            icon={<ChevronLeft />}
            variant={"flat"}
            className={cn("-left-8 h-7 min-h-7 w-7 min-w-7 sm:hidden")}
          />
          <CarouselNext
            icon={<ChevronRight />}
            variant={"flat"}
            className={cn("-right-8 h-7 min-h-7 w-7 min-w-7 sm:hidden")}
          />
        </Carousel>
      ) : (
        <WeekCard
          week={weeks[0]}
          selected={selected}
          className={className}
          onClick={handleOnClick}
          renderContextMenu={renderContextMenu}
        />
      )}
    </div>
  );
}

type WeekCardProps = {
  week: Date[];
  selected: Date;
  onClick: (day: Date) => void;
  renderContextMenu?: (
    children: ReactElement,
    day: Date,
    key: string,
  ) => ReactElement;
  className?: string;
};

function WeekCard({
  week,
  renderContextMenu,
  selected,
  className,
  onClick,
}: WeekCardProps) {
  return (
    <div className="flex w-full gap-0.5">
      {week.map((day) => {
        const elKey = day.toString();
        const weekDay = format(day, "ccc", { locale: pl });
        const monthDate = format(day, "d", { locale: pl });
        const today = isToday(day);
        const sameDay = isSameDay(day, selected);

        const content = (
          <button
            key={"weekDay_" + elKey}
            data-selected={sameDay}
            className={cn(
              "flex h-13 w-11 min-w-7 cursor-pointer select-none flex-col items-center gap-1 rounded-md py-1 opacity-100 transition-all duration-100 ease-out hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ring disabled:opacity-50 data-[selected=false]:bg-bg-container data-[selected=true]:bg-bg-brand hover:data-[selected=false]:bg-bg-element",
              className,
            )}
            onClick={() => onClick(day)}
          >
            <small
              className={cn(
                "text-[9px]",
                sameDay ? "text-fg-brand-on" : "text-fg-muted",
              )}
            >
              {weekDay}
            </small>
            <span
              className={cn(
                "text-md",
                sameDay ? "text-fg-brand-on" : "text-fg-secondary",
              )}
            >
              {monthDate}
            </span>
            {today && (
              <span
                className={cn(
                  "h-1 min-h-1 w-1 min-w-1 rounded-full",
                  sameDay ? "bg-fg-brand-on" : "bg-fg-muted",
                )}
              />
            )}
          </button>
        );
        if (!renderContextMenu) {
          return content;
        }
        return renderContextMenu(content, day, "contextDay_" + elKey);
      })}
    </div>
  );
}
