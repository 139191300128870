import CreateMeeting from "@/components/features/calendar/form/CreateMeeting";
import { Button } from "@/components/ui/button/Button";
import { Dialog } from "@/components/ui/dialog/Dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip/Tooltip";
import { useCredentials } from "@/store/authStore";
import { setNavigateT } from "@/types/calendar";
import { isToday, isValid } from "date-fns";
import { CalendarCheck, Plus } from "lucide-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

interface CalendarNavigationOptionsProps {
  date: Date;
  setNavigate: setNavigateT;
}

export default function CalendarNavigationOptions({
  date,
  setNavigate,
}: CalendarNavigationOptionsProps) {
  const { lvl } = useCredentials();
  const [openAddMeeting, setOpenAddMeeting] = useState<boolean>(false);

  const location = useLocation();

  // const isActionCreateMeeting = useMemo(() => {
  //   if (searchParams.get("action") === "createMeeting") {
  //     return true;
  //   }
  //   return false;
  // }, [searchParams]);

  const isStateCreateMeeting = useMemo(() => {
    if (
      location &&
      location.state &&
      location.state.action === "createMeeting"
    ) {
      return true;
    }
    return false;
  }, [location]);

  useEffect(() => {
    if (isStateCreateMeeting) {
      handleOpenAddMeeting();
    }
  }, [isStateCreateMeeting]);

  const defaultValues = useMemo(() => {
    if (location.state && location.state.defaultValues) {
      window.history.replaceState({}, "");
      return location.state.defaultValues;
    }
    return undefined;
  }, [location]);

  const handleSetToday = () => {
    setNavigate({ newDate: new Date() });
  };
  const handleOpenAddMeeting = () => {
    setOpenAddMeeting(true);
  };
  const checkIsToday = (date: Date): boolean => {
    if (isValid(date)) {
      return isToday(date);
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Dialog open={openAddMeeting} onOpenChange={setOpenAddMeeting}>
        <CreateMeeting
          onOpenChange={setOpenAddMeeting}
          defaultValues={defaultValues}
        />
      </Dialog>
      <div className={"flex flex-row gap-2"}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant={"outline"}
                variantColor={"muted"}
                iconPosition={"only"}
                icon={<CalendarCheck />}
                disabled={checkIsToday(date)}
                onClick={handleSetToday}
              />
            </TooltipTrigger>
            <TooltipContent>
              <p className={"text-sm text-fg-primary"}>Dzisiaj</p>
            </TooltipContent>
          </Tooltip>

          {lvl > 0 ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={"solid"}
                  variantColor={"brand"}
                  iconPosition={"only"}
                  icon={<Plus />}
                  onClick={handleOpenAddMeeting}
                />
              </TooltipTrigger>
              <TooltipContent>
                <p className={"text-sm text-fg-primary"}>Dodaj</p>
              </TooltipContent>
            </Tooltip>
          ) : null}
        </TooltipProvider>
      </div>
    </Fragment>
  );
}
