import { httpErrorHandler } from "@/api/api";
import { useGetAssignedMeetingsQuery as useGetMeetings } from "@/api/queries/meetingsQuery";
import { mapMeetingsByDate } from "@/components/features/calendar/layout/content/map-meetings-by-date";
import Meeting from "@/components/features/meeting/Meeting";
import MeetingCalendarCard from "@/components/features/meeting/MeetingCalendarCard";
import { Button } from "@/components/ui/button/Button";
import { ToastAction } from "@/components/ui/toast/Toast";
import { useToast } from "@/components/ui/toast/useToast";
import useElementData from "@/hooks/useElementData";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { cn } from "@/lib/utils";
import { VIEW_KEY } from "@/page/pages/calendar/CalendarPage";
import { setNavigateT } from "@/types/calendar";
import { MeetingsI } from "@/types/meetings";
import { getMonth } from "@/utils/dateFormat";
import { Portal } from "@radix-ui/react-portal";
import {
  endOfDay,
  endOfMonth,
  format,
  getDate,
  isToday,
  startOfDay,
  startOfMonth,
} from "date-fns";
import pl from "date-fns/locale/pl";
import { X } from "lucide-react";
import { useEffect, useRef, useState } from "react";

interface CalendarContentWeekProps {
  date: Date;
  setNavigate: setNavigateT;
}

export default function CalendarContentMonth({
  date,
  setNavigate,
}: CalendarContentWeekProps) {
  const month = getMonth(date, { fixedWeeks: true });
  const { toast } = useToast();
  const params = {
    before: endOfDay(endOfMonth(date)).toISOString(),
    after: startOfDay(startOfMonth(date)).toISOString(),
  };
  const isMobile = useMediaQueryHook("sm");

  const { data = [], isError, error, refetch } = useGetMeetings(params);
  const meetings = mapMeetingsByDate(month, data);

  useEffect(() => {
    if (isError) {
      const { title, detail } = httpErrorHandler(error);
      toast({
        variant: "destructive",
        title: title,
        description: detail,
        action: (
          <ToastAction altText="Try again" onClick={() => refetch()}>
            Ponów
          </ToastAction>
        ),
      });
    }
  }, [error, isError, refetch, toast]);

  const handleOnClick = (monthDayDate: Date): void => {
    setNavigate({ newDate: monthDayDate, newViewKey: VIEW_KEY.DAY });
  };
  const isSameMonth = (monthDayDate: Date) =>
    monthDayDate.getMonth() === date.getMonth();
  return (
    <div
      className={
        "relative grid h-full w-full grid-cols-7 grid-rows-6 border-t-1 p-0 [&>*:nth-child(7n+1)]:border-l-1"
      }
    >
      {month.map((monthDayDate) => {
        return (
          <div
            key={monthDayDate.toISOString()}
            className={cn(
              "relative flex h-full flex-col overflow-hidden border-b-1 border-r-1 border-border p-px",
            )}
            onClick={() => isMobile && handleOnClick(monthDayDate)}
          >
            <div className={"relative"}>
              <p
                className={cn(
                  "m-px text-xs",
                  isSameMonth(monthDayDate) || "opacity-40",
                  isToday(monthDayDate) && "text-fg-accent",
                  isMobile ||
                    "flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded-xs hover:bg-bg-muted-subtle hover:opacity-80",
                )}
                onClick={() => isMobile || handleOnClick(monthDayDate)}
              >
                {getDate(monthDayDate)}
              </p>
            </div>
            <CalendarContentDataMonth
              date={monthDayDate}
              queryParams={params}
              meetings={meetings[monthDayDate.toISOString()]}
            />
          </div>
        );
      })}
    </div>
  );
}

interface CalendarContentDataMonthProps {
  date: Date;
  meetings: MeetingsI[] | [];
  queryParams: { after: string; before: string };
}

function CalendarContentDataMonthPopover({
  meetings,
  position,
  date,
  queryParams,
}: {
  meetings: MeetingsI[] | [];
  position: DOMRect | undefined;
  date: Date;
  queryParams: { after: string; before: string };
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const meetingDataRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([popoverRef, meetingDataRef], () => {
    setIsPopoverOpen(false);
  });

  if (!meetings.length || !position) return null;

  const { height, width, left, top } = position;

  return (
    <>
      <button
        onClick={() => setIsPopoverOpen(true)}
        className={
          "flex h-6 w-full items-center gap-1 truncate rounded-xs px-1 text-center text-[clamp(0.6rem,1.5vw,0.875rem)] text-fg-muted hover:bg-bg-muted-subtle"
        }
      >
        +{meetings.length} rozwiń
      </button>
      <Portal
        className={cn(
          "fixed inset-0 z-40 bg-transparent",
          isPopoverOpen || "h-0 w-0",
        )}
      >
        <div
          ref={popoverRef}
          className={
            "flex flex-col gap-1 overflow-hidden rounded-lg border border-border bg-bg-container p-1 pb-5"
          }
          style={
            isPopoverOpen
              ? {
                  position: "fixed",
                  left: left - 10,
                  minHeight: height + 20,
                  top: top - 10,
                  width: width + 20,
                }
              : {
                  position: "fixed",
                  display: "none",
                  left: left,
                  top: top,
                  width: 0,
                  height: 0,
                }
          }
        >
          <div
            className={"flex h-10 w-full flex-row items-center justify-between"}
          >
            <p className={"ml-2 text-sm text-fg-muted"}>
              {format(date, "do", { locale: pl })}{" "}
              {format(date, "EEE", { locale: pl })}
            </p>
            <Button
              onClick={() => setIsPopoverOpen(false)}
              variant={"ghost"}
              variantColor={"muted"}
              iconPosition={"only"}
              size={"sm"}
              className={"rounded-md"}
              icon={<X />}
            />
          </div>

          <div>
            {meetings.map((meeting) => (
              <Meeting
                ref={meetingDataRef}
                key={meeting.id}
                meeting={meeting}
                queryParams={queryParams}
              >
                <MeetingCalendarCard meeting={meeting} />
              </Meeting>
            ))}
          </div>
        </div>
      </Portal>
    </>
  );
}

function CalendarContentDataMonth({
  date,
  queryParams,
  meetings,
}: CalendarContentDataMonthProps) {
  const isMobile = useMediaQueryHook("sm");
  const targetRef = useRef<HTMLDivElement>(null);

  const [{ height: targetHeight }, domRect] = useElementData<HTMLDivElement>(
    targetRef,
    true,
  );

  const [intersectingCount, setIntersectingCount] = useState<number>(0);

  const intersectionMeetings = meetings.slice(0, intersectingCount);
  const hiddenMeetings = meetings.slice(intersectingCount);

  useEffect(() => {
    const targetElement = targetRef.current;
    if (targetElement) {
      let count = 0;
      // 24 is the height of the top padding
      let height = targetHeight - 24;
      // 24 is the height of the child element
      const childrenHeight = 24;
      meetings.forEach(() => {
        height -= childrenHeight;
        if (height >= 0) {
          count++;
        }
      });
      if (count < meetings.length) count--;
      setIntersectingCount(count);
    }
  }, [targetRef, targetHeight, meetings]);

  let content;
  if (isMobile) {
    content = intersectionMeetings.map((meeting) => (
      <MeetingCalendarCard key={meeting.id} meeting={meeting} />
    ));
  } else {
    content = intersectionMeetings.map((meeting) => (
      <Meeting key={meeting.id} meeting={meeting} queryParams={queryParams}>
        <MeetingCalendarCard meeting={meeting} />
      </Meeting>
    ));
  }

  return (
    <div
      ref={targetRef}
      className={"absolute inset-0 flex flex-col overflow-hidden pt-6"}
    >
      <div>
        {content}
        <CalendarContentDataMonthPopover
          date={date}
          meetings={hiddenMeetings}
          queryParams={queryParams}
          position={domRect}
        />
      </div>
    </div>
  );
}
