import WeekCards from "@/components/features/calendar/WeekCards";
import { Button } from "@/components/ui/button/Button";
import { Label } from "@/components/ui/label/Label";
import { cn } from "@/lib/utils";
import { endOfWeek, format } from "date-fns";
import pl from "date-fns/locale/pl";
import { ArrowUpRight } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type MeetingsListHeaderProps = {
  date: Date;
  setDate: (day: Date) => void;
  defaultNavigate?: string;
  renderContextMenu?: (
    content: JSX.Element,
    day: Date,
    key: string,
  ) => JSX.Element;
  className?: string;
};

export default function MeetingsListHeader({
  date,
  setDate,
  defaultNavigate = "/calendar/?viewKey=month",
  renderContextMenu,
  className,
}: MeetingsListHeaderProps) {
  const navigate = useNavigate();
  const [currentVisibleWeekDay, setCurrentVisibleWeekDay] = useState<Date>(
    endOfWeek(date, {
      weekStartsOn: 1,
    }),
  );
  return (
    <div className={cn("flex flex-col gap-5", className)}>
      <div className={"flex items-center justify-between px-4"}>
        <Label className={"text-xl font-semibold text-fg-primary"}>
          {format(currentVisibleWeekDay, "LLLL yyyy", { locale: pl })}
        </Label>
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"muted"}
          icon={<ArrowUpRight />}
          iconPosition={"only"}
          onClick={() => navigate(defaultNavigate)}
        />
      </div>
      <div className={"flex w-full justify-center"}>
        <div className={"flex grow gap-1 overflow-x-auto px-4 py-1"}>
          <WeekCards
            selected={date}
            onSelect={setDate}
            visibleWeeksOffset={8}
            currentVisibleWeekDay={currentVisibleWeekDay}
            setCurrentVisibleWeekDay={setCurrentVisibleWeekDay}
            className={"w-full"}
            renderContextMenu={renderContextMenu}
          />
        </div>
      </div>
    </div>
  );
}
