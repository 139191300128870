import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import useElementData from "@/hooks/useElementData";
import { AssignGroupUsersI, AssignedGroupI } from "@/types/groups";
import { getAvatar } from "@/utils/getAvatar";
import { format, formatDistance, parseISO } from "date-fns";
import pl from "date-fns/locale/pl";
import { ArrowUpRight, User, Users } from "lucide-react";
import { Fragment, forwardRef, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

function sliceUsers(users: AssignGroupUsersI[], containerWidth: number) {
  let sliceIndex = containerWidth > 0 ? Math.floor(containerWidth / 30) : 4;
  sliceIndex = sliceIndex > 0 ? sliceIndex - 1 : sliceIndex;
  return [users.slice(0, sliceIndex), users.slice(sliceIndex)];
}

export const GroupBoxSkeleton = forwardRef<HTMLDivElement>(
  (props = {}, ref) => {
    return (
      <div
        ref={ref}
        className={
          "flex flex-col rounded-lg border-1 border-border bg-bg-container"
        }
        {...props}
      >
        <div className={"flex items-center gap-3 border-b-1 border-border p-4"}>
          <Skeleton className={"h-10 w-10 rounded-sm"} />
          <Skeleton className={"h-5 w-[12ch] rounded-sm text-lg"} />
        </div>
        <div className={"flex h-full flex-col gap-3 p-4"}>
          <div className={"flex flex-col gap-2"}>
            <Skeleton className={"h-3 w-[11ch] rounded-sm text-xs"} />
            <div className={"flex flex-wrap gap-1"}>
              <Skeleton className={"h-5 w-14 rounded-sm"} />
              <Skeleton className={"h-5 w-13 rounded-sm"} />
              <Skeleton className={"h-5 w-13 rounded-sm"} />
            </div>
          </div>
          <div className={"flex flex-col gap-2"}>
            <Skeleton className={"h-3 w-[18ch] rounded-sm text-xs"} />
            <div className={"flex flex-wrap gap-1"}>
              <Skeleton className={"h-5 w-14 rounded-sm"} />
              <Skeleton className={"h-5 w-13 rounded-sm"} />
              <Skeleton className={"h-5 w-13 rounded-sm"} />
            </div>
          </div>
          <div className={"flex items-center justify-between gap-2"}>
            <div className={"flex"}></div>
            <Skeleton className={"h-11 w-[7rem] rounded-md"} />
          </div>
        </div>
      </div>
    );
  },
);

GroupBoxSkeleton.displayName = "GroupBoxSkeleton";

interface GroupBoxProps {
  group: AssignedGroupI;
  onMouseEnter?: () => void;
  onFocus?: () => void;
}

export function GroupCard({
  group,
  onFocus,
  onMouseEnter,
  ...props
}: GroupBoxProps) {
  const avatarsContainerRef = useRef<HTMLDivElement>(null);
  const { id, name, meetings, users } = group;
  const [{ width }] = useElementData(avatarsContainerRef);
  const avatarGroup = useMemo(() => {
    const [firstGroup, secondGroup] = sliceUsers(users, width);

    return (
      <Fragment>
        {firstGroup.map(({ id, name, surname, avatarURL }) => {
          return (
            <Avatar
              key={id}
              size={"sm"}
              className={"h-8 min-h-8 w-8 min-w-8 rounded-md"}
            >
              <AvatarImage
                src={getAvatar(avatarURL)}
                alt={`${name} ${surname}`}
              />
              <AvatarFallback>
                <User className={"h-4 w-4"} />
              </AvatarFallback>
            </Avatar>
          );
        })}
        {secondGroup.length > 0 && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                size={"sm"}
                variant={"flat"}
                variantColor={"muted"}
                className={
                  "h-8 max-h-8 min-h-8 w-8 min-w-8 max-w-8 rounded-md border-1 p-0 text-fg-muted"
                }
              >
                +{secondGroup.length}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                {secondGroup.map(({ id, name, surname, avatarURL }) => (
                  <DropdownMenuLabel
                    key={id}
                    className={"flex items-center gap-2 py-1 font-normal"}
                  >
                    <Avatar
                      key={id}
                      size={"sm"}
                      className={"h-8 min-h-8 w-8 min-w-8 rounded-md"}
                    >
                      <AvatarImage
                        src={getAvatar(avatarURL)}
                        alt={`${name} ${surname}`}
                      />
                      <AvatarFallback>
                        <User className={"h-4 w-4"} />
                      </AvatarFallback>
                    </Avatar>
                    <p>{name + " " + surname}</p>
                  </DropdownMenuLabel>
                ))}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </Fragment>
    );
  }, [width]);

  return (
    <div
      className={
        "flex flex-col rounded-lg border-1 border-border bg-bg-container"
      }
      onMouseEnter={onMouseEnter}
      onFocus={onFocus}
      {...props}
    >
      <div className={"flex items-center gap-3 border-b-1 border-border p-4"}>
        <Avatar size={"md"}>
          <AvatarImage src={""} alt={"avatar"} />
          <AvatarFallback>
            <Users className={"h-4 w-4"} />
          </AvatarFallback>
        </Avatar>
        <h5 className={"w-full truncate text-lg font-semibold text-fg-primary"}>
          {name}
        </h5>
      </div>
      <div className={"flex h-full flex-col gap-3 p-4"}>
        <div className={"flex flex-col gap-2"}>
          <p className={"text-xs font-medium text-fg-muted"}>Tagi grupy:</p>
          <div className={"flex flex-wrap gap-1"}></div>
        </div>
        <div className={"flex flex-col gap-2"}>
          <p className={"text-xs font-medium text-fg-muted"}>
            Najbliższe zajęcia:
          </p>
          <div className={"flex flex-wrap gap-1"}>
            {meetings.length ? (
              <Badge key={id} variant={"muted"} size={"sm"} hideDot>
                {format(parseISO(meetings[0].startDate), "HH:mm")} -{" "}
                {format(parseISO(meetings[0].endDate), "HH:mm")}
                {" | "}
                {formatDistance(parseISO(meetings[0].startDate), new Date(), {
                  addSuffix: true,
                  locale: pl,
                })}
              </Badge>
            ) : null}
          </div>
        </div>
        <div className={"mt-auto flex items-center justify-between gap-2"}>
          <div
            ref={avatarsContainerRef}
            className={"flex h-full w-full items-end -space-x-2"}
          >
            {avatarGroup}
          </div>
          <Link to={`/groups/${id}`} state={group} className={"shrink-0"}>
            <Button
              variant={"outline"}
              variantColor={"muted"}
              icon={<ArrowUpRight />}
              iconPosition={"right"}
            >
              Otwórz
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
