import MediaAudioViewer from "@/components/features/media/MediaAudioViewer";
import MediaFileViewer from "@/components/features/media/MediaFileViewer";
import MediaImageViewer from "@/components/features/media/MediaImageViewer";
import MediaPDFViewer from "@/components/features/media/MediaPDFViewer";
import MediaVideoViewer from "@/components/features/media/MediaVideoViewer";
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "@/components/ui/dialog/Dialog";
import { cn } from "@/lib/utils";
import { DirectoryFileI, FilesI } from "@/types/files";
import { FileTypeE, getFileType } from "@/utils/getFileType";
import getFileUrl from "@/utils/getFileUrl";
import { DialogTitle } from "@radix-ui/react-dialog";
import { Dispatch, SetStateAction, useMemo } from "react";

type MediaViewerProps = {
  file?: DirectoryFileI | File | FilesI;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>> | ((bool: boolean) => void);
};

export type MediaViewerFileT = {
  file: FilesI | File | DirectoryFileI;
};

type MediaViewerFileDataReturnT = {
  fileURL?: string;
  fileURLObject?: string;
  mimeType: string;
};

export default function MediaViewer({
  file,
  open,
  onOpenChange,
}: MediaViewerProps) {
  const { content, styles } = useMemo(() => {
    if (!file) return { content: "Brak pliku", styles: "h-full" };
    const { serveType, subtype } = getFileType(file);

    if (serveType && subtype === "pdf") {
      return {
        content: <MediaPDFViewer file={file} />,
        styles:
          "h-full w-[calc(100%_-_var(--space-12))] max-w-[var(--breakpoint-laptop)] sm:w-full sm:max-w-full",
      };
    } else if (serveType === FileTypeE.IMAGE) {
      return { content: <MediaImageViewer file={file} />, styles: "h-full" };
    } else if (serveType === FileTypeE.VIDEO) {
      return { content: <MediaVideoViewer file={file} />, styles: "h-full" };
    } else if (serveType === FileTypeE.AUDIO) {
      return { content: <MediaAudioViewer file={file} />, styles: "h-fit" };
    } else {
      return { content: <MediaFileViewer file={file} />, styles: "h-fit" };
    }
  }, [file]);

  return (
    <MediaViewerWrapper
      open={open}
      onOpenChange={onOpenChange}
      fileName={file?.name}
      className={styles}
    >
      {content}
    </MediaViewerWrapper>
  );
}

type MediaViewerWrapperProps = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>> | ((bool: boolean) => void);
  fileName?: string;
  className?: string;
  children: React.ReactNode;
};

export function MediaViewerWrapper({
  open,
  onOpenChange,
  fileName,
  className,
  children,
}: MediaViewerWrapperProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={cn(className, "")}>
        <DialogHeader>
          <DialogTitle>{fileName}</DialogTitle>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export function MediaViewerFileData(
  file?: FilesI | File | DirectoryFileI,
): MediaViewerFileDataReturnT {
  return useMemo(() => {
    if (file && "id" in file) {
      return {
        fileURL: getFileUrl(file.id),
        fileURLObject: undefined,
        mimeType: file.fileType,
      };
    } else if (file) {
      return {
        fileURL: undefined,
        fileURLObject: URL.createObjectURL(file),
        mimeType: file.type,
      };
    }
    return { fileURL: undefined, fileURLObject: undefined, mimeType: "" };
  }, [file]);
}
