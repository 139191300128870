import { Badge } from "@/components/ui/badge/Badge";
import { Link } from "@/components/ui/link/Link";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { MeetingI } from "@/types/meetings";
import { format, parseISO } from "date-fns";
import pl from "date-fns/locale/pl";
import { forwardRef, useMemo } from "react";
import { Link as RRLink } from "react-router-dom";

const MeetingCard = forwardRef<HTMLButtonElement, { meeting: MeetingI }>(
  ({ meeting, ...props }, ref) => {
    const {
      id,
      name,
      startDate,
      endDate,
      type,
      meetingURL,
      locationId,
      location,
      isCancelled,
    } = meeting;

    const weekDay = format(parseISO(startDate), "ccc", { locale: pl });
    const monthDate = format(parseISO(startDate), "d", { locale: pl });

    const content = useMemo(() => {
      if (isCancelled) {
        return (
          <div>
            <p className={"text-start text-md font-medium text-fg-destructive"}>
              {name}
            </p>
            <Badge variant={"destructive"} size={"sm"} hideDot>
              Odwołane
            </Badge>
          </div>
        );
      } else {
        return (
          <div>
            <p
              className={
                "truncate text-start text-md font-medium text-fg-secondary"
              }
            >
              {name}
            </p>
            <div className={"flex gap-2"}>
              <p className={"shrink-0 text-nowrap text-sm text-fg-muted"}>
                {format(parseISO(startDate), "H:mm") +
                  " - " +
                  format(parseISO(endDate), "H:mm")}
              </p>
              {meetingURL && (
                <Link
                  size={"sm"}
                  href={meetingURL}
                  openInNewTab
                  className="truncate"
                >
                  Link do spotkania
                </Link>
              )}
              {location && (
                <p
                  className={
                    "shrink-0 truncate text-nowrap text-sm text-fg-muted"
                  }
                >
                  {location?.name}
                </p>
              )}
            </div>
          </div>
        );
      }
    }, [endDate, isCancelled, locationId, meetingURL, name, startDate]);

    return (
      <RRLink to={`/calendar/meeting/${id}`} className="w-full">
        <button
          ref={ref}
          role={"button"}
          className={cn(
            "flex w-full gap-2 rounded-md border-1 border-border bg-bg-container p-2 opacity-100 transition-all duration-100 ease-out hover:bg-bg-element/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ring",
          )}
          {...props}
        >
          <div
            className={"flex h-11 w-11 flex-col items-center justify-center"}
          >
            <span className={"text-xs text-fg-primary"}>{weekDay}</span>
            <span className={"text-md text-fg-primary"}>{monthDate}</span>
          </div>
          <div
            className={cn(
              "h-11 w-1 min-w-1 max-w-1 grow rounded-xs",
              type === "remote" ? "bg-bg-brand" : "bg-bg-accent",
              isCancelled && "bg-bg-destructive",
            )}
          />
          <div className={"flex flex-col justify-center gap-0.5"}>
            {content}
          </div>
        </button>
      </RRLink>
    );
  },
);
MeetingCard.displayName = "MeetingCard";

type MeetingCardSkeletonProps = {
  className?: string;
};

const MeetingCardSkeleton = forwardRef<
  HTMLDivElement,
  MeetingCardSkeletonProps
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        "flex gap-2 rounded-md border-1 border-border bg-bg-container p-2",
        className,
      )}
    >
      <Skeleton
        className={
          "flex h-11 w-11 flex-col items-center justify-center rounded-md"
        }
      />
      <div className={cn("bg-bg-border h-full w-1 min-w-1 rounded-xs")} />
      <div className={"flex flex-col justify-center gap-1"}>
        <Skeleton className={"h-3 w-14 rounded-sm"} />
        <Skeleton className={"h-3 w-12 rounded-sm"} />
      </div>
    </div>
  );
});

MeetingCardSkeleton.displayName = "MeetingCardSkeleton";

export { MeetingCard, MeetingCardSkeleton };
