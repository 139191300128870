import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

const sizeOfMediaQuery = {
  sm: "640",
  md: "959",
  lg: "1200",
  xl: "1440",
} as const;

function useMediaQueryHook(size: "sm" | "md" | "lg" | "xl") {
  const query = useMemo(
    () => `(max-width: ${sizeOfMediaQuery[size]}px)`,
    [size],
  );

  return useMediaQuery({ query });
}

export default useMediaQueryHook;
