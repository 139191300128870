import {
  useGetChatQuery,
  usePostFileUploadMutation,
} from "@/api/queries/chatQueries";
import ChatContent from "@/components/features/chat/layout/chat-conversation/ChatContent";
import ChatFooter from "@/components/features/chat/layout/chat-conversation/ChatFooter";
import ChatHeader from "@/components/features/chat/layout/chat-conversation/ChatHeader";
import { socketIOClient } from "@/socket/socket";
import { useCredentials } from "@/store/authStore";
import { useChatStore } from "@/store/chatStore";
import { useStateStore } from "@/store/stateStore";
import { MessageConfirmationI, MessageI } from "@/types/chat";
import { messageTypeT } from "@/types/chat";
import { FileTypeE, getServeFileType } from "@/utils/getFileType";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

export type messageFormT = {
  content: string;
  files: File[];
  replyingToId: number | undefined;
  replyingTo: MessageI | undefined;
};
export type messageFormTextT = Omit<messageFormT, "files">;
export type messageFormMediaT = Omit<messageFormT, "content">;

const CHAT_CONVERSATION_LIMIT = 20;
export const CHAT_MAX_FILE_SIZE = 500 * 1024 * 1024;
export const CHAT_MAX_MESSAGE_SIZE = 2056;

export default function Chat() {
  const { state } = useLocation();
  const { type, name, id } = state;
  const {
    id: userId,
    name: userName,
    surname: userSurname,
    avatarURL: userAvatarURL,
  } = useCredentials();
  const { setAvatarMap } = useStateStore();

  const { data, isSuccess } = useGetChatQuery(id);
  useEffect(() => {
    if (isSuccess && data) {
      setAvatarMap(
        new Map(data.users.map(({ id, avatarURL }) => [id, avatarURL])),
      );
    }
  }, [isSuccess, data]);

  const contentRef = useRef<HTMLDivElement>(null);
  const [socket, setSocket] = useState<Socket | undefined>();
  const {
    isConnected,
    last,
    newest,
    prependMessages,
    appendMessages,
    confirmMessage,
    setDefault,
    setIsConnected,
    setIsLoading,
    setIsError,
    setIsDataLoading,
    setNewest,
    setLast,
    setHasPreviousPage,
    deleteMessage,
  } = useChatStore();
  const { mutateAsync: fileUpload } = usePostFileUploadMutation();

  useEffect(() => {
    if (!id) return;
    const newSocket = socketIOClient(id);
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    setDefault();
    socket?.connect();
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    function onErrorEvent() {
      socket?.disconnect();
      setIsError(true);
      setIsLoading(false);
    }
    function onConnect() {
      setIsConnected(true);
      setIsLoading(false);
      setIsError(false);
    }
    function onDisconnect() {
      setIsConnected(false);
      setIsLoading(false);
    }
    function onMessageEvent(message: MessageI) {
      if (message.id === undefined) return;
      appendMessages([message]);
      setNewest(message.id);
    }
    function onMessageDelete(value: { id: number; content: string }) {
      deleteMessage(value);
    }

    function onMessageConfirmationEvent(response: MessageConfirmationI) {
      confirmMessage(response);
    }
    function onReloadEvent(messages: MessageI[]) {
      if (!messages.length) return;
      const lastMessageId = messages.at(-1)?.id;
      if (lastMessageId) setNewest(lastMessageId);
      appendMessages(messages);
      setIsDataLoading(false);
    }
    function onGetHistoryEvent(nextMessages: MessageI[]) {
      const lastMessageId = nextMessages.at(-1)?.id;
      const firstMessageId = nextMessages[0]?.id;

      if (nextMessages.length < CHAT_CONVERSATION_LIMIT) {
        setHasPreviousPage(false);
      }
      if (useChatStore.getState().messages.length === 0 && lastMessageId) {
        setNewest(lastMessageId);
      }
      if (nextMessages.length && firstMessageId) {
        prependMessages(nextMessages);
        setLast(firstMessageId);
      }

      setIsDataLoading(false);
    }

    socket.io.on("error", onErrorEvent);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", onMessageEvent);
    socket.on("msgDelete", onMessageDelete);
    socket.on("confirm", onMessageConfirmationEvent);
    socket.on("reload", onReloadEvent);
    socket.on("getHistory", onGetHistoryEvent);

    return () => {
      socket.io.off("error", onErrorEvent);
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("message", onMessageEvent);
      socket.off("msgDelete", onMessageDelete);
      socket.off("confirm", onMessageConfirmationEvent);
      socket.off("reload", onReloadEvent);
      socket.off("getHistory", onGetHistoryEvent);
    };
  }, [socket]);

  useEffect(() => {
    if (isConnected && newest) {
      socket?.emit("reload", { newest: newest });
    }
  }, [isConnected, newest, socket]);

  const getHistory = () => {
    if (isConnected) {
      setIsDataLoading(true);
      socket?.emit("getHistory", {
        limit: CHAT_CONVERSATION_LIMIT,
        last: last,
      });
    }
  };

  const onMessageUpload = ({
    content,
    replyingToId,
    replyingTo,
  }: messageFormTextT) => {
    const tempUUID = uuidv4();

    const message: MessageI = {
      type: "text",
      content: content,
      createdAt: new Date().toISOString(),
      senderId: userId,
      sender: {
        id: userId,
        name: userName,
        surname: userSurname,
        avatarURL: userAvatarURL as string,
      },
      attachment: null,
      tempUUID: tempUUID,
      replyingToId: replyingToId,
      replyingTo: replyingTo,
    };
    appendMessages([message]);
    socket?.emit("message", message);
  };
  const onFileUpload = ({
    files,
    replyingToId,
    replyingTo,
  }: messageFormMediaT) => {
    files.forEach(async (file) => {
      const serveType = getServeFileType(file.type, {
        pick: [FileTypeE.IMAGE, FileTypeE.VIDEO, FileTypeE.AUDIO],
      });
      const tempUUID = uuidv4();

      const message: MessageI = {
        type: serveType as messageTypeT,
        content: file.name,
        senderId: userId,
        sender: {
          id: userId,
          name: userName,
          surname: userSurname,
          avatarURL: userAvatarURL as string,
        },
        attachment: null,
        file: file,
        createdAt: new Date().toISOString(),
        tempUUID: tempUUID,
        replyingToId: replyingToId,
        replyingTo: replyingTo,
      };
      appendMessages([message]);
      await fileUpload({
        id: id,
        file: file,
        type: serveType,
        content: file.name,
        replyingToId: replyingToId,
        tempUUID: tempUUID,
        socketId: socket?.id,
      })
        .then((response) => {
          confirmMessage(response);
        })
        .catch(() => {
          confirmMessage({
            action: "failed",
            tempUUID: tempUUID,
            content: "Nie udało się wysłać wiadomości",
            type: "text",
          });
        });
    });
  };

  const onDelete = (id: number) => {
    socket?.emit("msgDelete", { id: id });
  };

  const onSubmit = async ({
    content,
    files,
    replyingToId,
    replyingTo,
  }: messageFormT) => {
    if (content) {
      onMessageUpload({ content, replyingToId, replyingTo });
    }
    if (files) {
      onFileUpload({ files, replyingToId, replyingTo });
    }
  };

  const onReconnect = () => {
    setIsLoading(true);
    socket?.connect();
  };

  return (
    <section
      className={
        "flex h-full w-full flex-col rounded-lg border-1 border-border bg-bg-container"
      }
    >
      <ChatHeader
        name={name}
        id={id}
        conversationType={type}
        onReconnect={onReconnect}
      />
      <ChatContent
        ref={contentRef}
        getHistory={getHistory}
        onDelete={onDelete}
        conversationType={type}
      />
      <ChatFooter onSubmit={onSubmit} />
    </section>
  );
}
