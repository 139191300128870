import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import { cn } from "@/lib/utils";
import { forwardRef } from "react";

type GroupGradesProps = {
  className?: string;
};

const GroupGrades = forwardRef<HTMLDivElement, GroupGradesProps>(
  ({ className }: GroupGradesProps, ref) => {
    const handleNavigate = () => {
      console.log("Navigate to grades");
    };

    return (
      <div
        ref={ref}
        className={cn(
          "flex flex-col gap-4 overflow-auto rounded-lg border-1 border-border bg-bg-container p-4",
          className,
        )}
      >
        <GroupElementHeader
          label={"Ostatnie oceny"}
          callback={handleNavigate}
        />
        <div
          className={
            "flex flex-col gap-2 overflow-y-auto overscroll-contain px-4 pb-4"
          }
        ></div>
      </div>
    );
  },
);
GroupGrades.displayName = "GroupGrades";
export default GroupGrades;
