import { httpErrorHandler } from "@/api/api";
import { useGetGroupQuery } from "@/api/queries/groupsQueries";
import GroupFiles from "@/components/features/groups/GroupFiles";
import GroupGrades from "@/components/features/groups/GroupGrades";
import GroupMeetings from "@/components/features/groups/GroupMeetings";
import GroupQuickMenu from "@/components/features/groups/GroupQuickMenu";
import GroupTasks from "@/components/features/groups/GroupTasks";
import GroupHeader from "@/components/features/groups/layout/GroupHeader";
import { useToast } from "@/components/ui/toast/useToast";
import { cn } from "@/lib/utils";
import { useAuthStore } from "@/store/authStore";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

export default function GroupPage() {
  const { lvl } = useAuthStore.useCredentials();
  const { id } = useParams();
  const { toast } = useToast();
  const { data, isError, error } = useGetGroupQuery(Number(id));
  const lessonsRef = useRef<HTMLDivElement>(null);
  const filesRef = useRef<HTMLDivElement>(null);
  const tasksRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isError) {
      const { title, detail } = httpErrorHandler(error);
      toast({
        title: title,
        description: detail,
        variant: "destructive",
      });
    }
  }, [error, isError]);

  return (
    <div
      className={cn(
        "lg_md:grid-rows-[fit-content(128px)_min-content_minmax(200px,600px)_minmax(200px,600px)_minmax(200px,600px)] relative grid grow auto-cols-fr grid-rows-[fit-content(128px)_minmax(200px,min-content)_minmax(200px,1fr)_minmax(200px,1fr)] content-stretch justify-stretch gap-4 sm:grid-rows-[fit-content(128px)_min-content_minmax(600px,1fr)_minmax(600px,1fr)_minmax(600px,1fr)]",
        lvl > 0
          ? "lg_md:[grid-template-areas:'header_header''menu_menu''meetings_meetings''tasks_tasks''files_files'] [grid-template-areas:'header_header_meetings''menu_menu_meetings''files_tasks_meetings''files_tasks_meetings'] sm:[grid-template-areas:'header''menu''meetings''files''tasks']"
          : "lg_md:[grid-template-areas:'header_header''meetings_meetings''grades_grades''tasks_tasks''files_files'] [grid-template-areas:'header_header_meetings''grades_tasks_meetings''files_tasks_meetings''files_tasks_meetings'] sm:[grid-template-areas:'header''menu''meetings''grades''files''tasks']",
      )}
    >
      <GroupHeader className={"[grid-area:header]"} group={data} />
      <GroupQuickMenu
        className={cn("[grid-area:menu]", lvl < 1 && "hidden sm:flex")}
        group={data}
        refs={{ lessonsRef, filesRef, tasksRef }}
      />
      <GroupMeetings
        groupId={Number(id)}
        group={data}
        className={"[grid-area:meetings]"}
      />
      {lvl === 0 && <GroupGrades className={"[grid-area:grades]"} />}
      <GroupFiles
        ref={filesRef}
        className={"[grid-area:files]"}
        directoryId={data?.directoryId}
      />
      <GroupTasks ref={tasksRef} className={"[grid-area:tasks]"} />
    </div>
  );
}
