import { useGetMeetingQuery } from "@/api/queries/meetingsQuery";
import EditMeeting from "@/components/features/calendar/form/EditMeeting";
import GroupMembers from "@/components/features/groups/GroupMembers";
import LocationCard from "@/components/features/location/LocationCard";
import MeetingRemoteLink from "@/components/features/meeting/components/MeetingRemoteLink";
import { Button } from "@/components/ui/button/Button";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Label } from "@/components/ui/label/Label";
import { Separator } from "@/components/ui/separator/Separator";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { format, parseISO } from "date-fns";
import { Building, CalendarClock, PenLine } from "lucide-react";
import { Fragment, useState } from "react";
import { useParams } from "react-router";

export default function MeetingPage() {
  const { id } = useParams();
  const [openEditDialog, setEditDialog] = useState<boolean>(false);
  const { data, isPending, isSuccess, isError } = useGetMeetingQuery(
    Number(id),
  );
  const { lvl } = useCredentials();

  const handleOpenEdit = () => {
    setEditDialog(true);
  };

  return (
    <Fragment>
      {lvl > 0 && (
        <Dialog open={openEditDialog} onOpenChange={setEditDialog}>
          <EditMeeting
            id={Number(id)}
            open={openEditDialog}
            onOpenChange={setEditDialog}
          />
        </Dialog>
      )}

      <div
        className={
          "mb-4 flex w-full flex-wrap items-center gap-2 rounded-lg border-1 border-border bg-bg-container p-4"
        }
      >
        <h2 className={"truncate text-lg font-semibold"}>
          {isSuccess && data.name}
          {isPending && (
            <Skeleton
              className={"h-3 max-h-3 w-14 max-w-[256px] grow rounded-sm"}
            />
          )}
        </h2>
        <div className="flex h-11 grow gap-2">
          <div
            className={cn(
              "h-full w-1 min-w-1 max-w-1 grow rounded-xs",
              data?.type === "remote" ? "bg-bg-brand" : "bg-bg-accent",
              data?.isCancelled && "bg-bg-destructive",
            )}
          />
          <div
            className={
              "flex shrink-0 flex-col items-start justify-center gap-1"
            }
          >
            {isSuccess && data.location && (
              <div className={"inline-flex items-center gap-1"}>
                <Building className={"h-4 w-4 stroke-fg-secondary"} />
                <p
                  className={
                    "max-w-[25ch] truncate text-xs font-medium text-fg-secondary"
                  }
                >
                  {data.location.name}
                </p>
              </div>
            )}
            <div className={"inline-flex items-center gap-1"}>
              <CalendarClock className={"h-4 w-4 stroke-fg-secondary"} />
              <p
                className={
                  "max-w-[25ch] truncate text-xs font-medium text-fg-secondary"
                }
              >
                {isSuccess &&
                  format(parseISO(data.startDate), "H:mm") +
                    " - " +
                    format(parseISO(data.endDate), "H:mm")}
              </p>
            </div>
          </div>
          {lvl > 0 && (
            <Button
              className={"ml-auto"}
              variant={"outline"}
              variantColor={"muted"}
              iconPosition={"only"}
              icon={<PenLine />}
              onClick={handleOpenEdit}
            />
          )}
        </div>
      </div>
      {isError ? (
        <div className={"w-full p-4 text-fg-muted"}>
          <p>Wystąpił błąd</p>
        </div>
      ) : (
        <div className={"flex w-full grow flex-row gap-4 md:flex-col"}>
          <div
            className={
              "flex h-full flex-1 flex-col rounded-lg border-1 border-border bg-bg-container"
            }
          >
            <header className={"flex items-center justify-between gap-2 p-4"}>
              <div className={"flex h-10 items-center"}>
                <h3 className={"w-full truncate text-lg font-semibold"}>
                  Dane
                </h3>
              </div>
            </header>
            <Separator />
            <div className={"flex flex-col gap-6 p-4"}>
              <Label>Opis</Label>
              <div className={"flex min-h-12 flex-col gap-2"}>
                {isSuccess && data.desc && (
                  <p className={"w-full text-sm text-fg-secondary"}>
                    {data.desc}
                  </p>
                )}
                {isSuccess && !data.desc && (
                  <p className={"text-fg-muted"}>brak opisu</p>
                )}
                {isPending && (
                  <>
                    <Skeleton
                      className={"h-3 max-h-3 max-w-[256px] grow rounded-sm"}
                    />
                    <Skeleton
                      className={"h-3 max-h-3 max-w-[204px] grow rounded-sm"}
                    />
                  </>
                )}
              </div>
            </div>
            <Separator />
            <div className={"flex h-full flex-col gap-6 p-4"}>
              <Label>
                {isSuccess &&
                  data.type == "stationary" &&
                  data.location &&
                  "Lokacja"}
                {isSuccess && data.type == "remote" && "Link"}
              </Label>
              {isSuccess && data.type == "stationary" && data.location && (
                <LocationCard
                  location={data.location}
                  className={"max-h-[524px] min-h-[256px]"}
                />
              )}
              {isSuccess && data.type == "remote" && (
                <MeetingRemoteLink meeting={data} />
              )}
            </div>
          </div>
          <div
            className={
              "h-full w-[23rem] max-w-[23rem] grow rounded-lg border border-border bg-bg-container md:w-full md:max-w-full"
            }
          >
            <GroupMembers
              groups={data?.groups || []}
              users={data?.users || []}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}
